<template>
  <a-modal
    title="编辑分类"
    :width="700"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 父级 -->
            <a-form-item label="父级" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select v-decorator="['parentId']" allow-clear placeholder="请选择（若不选择，则默认为顶级分类）">
                <a-select-option v-for="(item, index) in parentList" :key="index" :value="item.status">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <!-- 分类名称 -->
            <a-form-item label="分类名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                :maxLength="6"
                placeholder="请输入分类名称"
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 分类图标 -->
            <a-form-item label="分类图标" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <div style="width: 104px; height: 104px">
                <a-upload
                  style="margin: auto"
                  name="avatar"
                  accept=".png,.jpg,jpeg,.gif"
                  list-type="picture-card"
                  :show-upload-list="false"
                  :customRequest="customUserRequest"
                  v-decorator="[
                    'picture',
                    { rules: [{ required: form.getFieldValue('parentId') ? true : false, message: '请上传！' }] },
                  ]"
                >
                  <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
                  <div v-else>
                    <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
              </div>
            </a-form-item>
            <!-- 排序 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                placeholder="请输入排序"
                :min="1"
                :max="9999"
                style="width: 60%; min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 状态 -->
            <a-form-item label="显示状态" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-radio-group
                name="radioGroup"
                v-decorator="['delFlag', { rules: [{ required: true, message: '请选择！' }] }]"
              >
                <a-radio :value="1"> 启用 </a-radio>
                <a-radio :value="3"> 禁用&nbsp;&nbsp;&nbsp; </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import {
  commodityClassEdit,
  commodityClassDetail,
  allPageList,
} from '@/api/modular/mallLiving/commodity/commodityClass'

import { uploadFile } from '@/api/modular/mallLiving/uploadFile'

export default {
  data() {
    return {
      loading: false,
      imageUrl: '',
      picture: '',
      allList: [],
      pictureLoading: false,
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 6,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
      labelCol_1: {
        xs: {
          span: 24,
        },
        sm: {
          span: 3,
        },
      },
      wrapperCol_1: {
        xs: {
          span: 24,
        },
        sm: {
          span: 20,
        },
      },
      cityList: [], //市区列表
      parentList: [], //父级列表
      className: '', //分类名称
      sort: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      id: '',
    }
  },

  methods: {
    // 初始化方法
    async edit(record) {
      //正常的初始化方法
      this.visible = true
      this.handleSearch('')
      this.confirmLoading = true
      this.picture = ''
      this.id = record.id

      await this.getInfo()

      var parentListTemp = this.allList.map((item) => {
        return {
          status: item.id,
          name: item.name,
        }
      })
      this.parentList = [...parentListTemp]
      //调取详情接口，回显数据
      commodityClassDetail({
        id: this.id,
      })
        .then((res) => {
          if (res.success) {
            var details = res.data
            this.picture = details.logo
            this.confirmLoading = false
            setTimeout(() => {
              this.form.setFieldsValue({
                parentId: details.parentId == -1 ? undefined : details.parentId,
                name: details.name,
                sort: details.sort,
                picture: details.logo,
                delFlag: details.delFlag,
              })
              //如果parentId为-1
              if (details.parentId == -1) {
                setTimeout(() => {
                  if (this.form.getFieldValue('parentId') == undefined) {
                    this.form.setFieldsValue({
                      isPublic: details.isPublic, //是否公用
                    })
                  }
                }, 50)
                setTimeout(() => {
                  if (this.form.getFieldValue('isPublic') == 2 && details.cityIds.length > 0) {
                    this.form.setFieldsValue({
                      cityIds: details.cityIds.split(','),
                    })
                  }
                }, 100)
              }
            }, 150)
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.confirmLoading = false
          }, 2000)
        })
    },
    handleSubmit() {
      this.confirmLoading = true

      this.form.validateFields((errors, values) => {
        if (!errors) {
          if (values.cityIds && values.cityIds.length > 0) {
            values.cityIds = values.cityIds.join(',') //将城市数组转换成字符串
          }
          // 如果未选择，将parentId作为空字符串提交
          if (values.parentId == undefined) {
            values.parentId = ''
          }
          // 自己不能作为自己的父级
          if (this.id == values.parentId) {
            this.$message.error('不可选择自己作为自己的父级')
            this.confirmLoading = false
            return
          } else if (values.bondPrice <= 0) {
            this.$message.error('保证金不能小于0')
            this.confirmLoading = false
            return
          } else if (values.maxBondPrice <= 0) {
            this.confirmLoading = false
            this.$message.error('最高保证金不能小于0')
            return
          } else if (values.maxBondPrice < values.bondPrice) {
            this.confirmLoading = false
            this.$message.error('最高保证金金额不能小于最低保证金金额')
            return
          }
          values.logo = this.picture
          delete values.picture
          values.bondPrice = values.parentId ? '' : values.bondPrice
          commodityClassEdit({
            id: this.id,
            ...values,
          }).then((res) => {
            if (res.success) {
              this.$message.success('编辑成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.provinceName = undefined //重置省份名称
      this.confirmLoading = false
      setTimeout(() => {
        this.form.resetFields() //重置表单
      }, 150)
    },
    //获取初始全部列表数据
    getInfo() {
      return allPageList({}).then((res) => {
        this.allList = res.data
      })
    },
    //搜索框搜索城市
    handleSearch(value) {
      this.axios
        .post('/sysDistrict/list', null, {
          data: {
            districtLevel: 3,
            districtName: value,
          },
        })
        .then((res) => {
          if (res.success) {
            this.cityList = res.data
          }
        })
    },

    formatFileName() {
      var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = ''

      for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n + new Date().getTime()
    },
    //自定义图片上传实现
    customUserRequest(filedata) {
      let that = this
      this.pictureLoading = true
      console.log(filedata)
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            this.picture = list[0]
            this.pictureLoading = false
            that.$nextTick(function () {
              that.$forceUpdate()
            })
          }
        }
      })
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
